* {
  /* margin: auto;
  padding: 0;
  box-sizing: border-box; */
  font-family: "Roboto";
}

.img {
  width: 100%;
  height: 5rem;
}

.Button {
  color: black;
}

/* The device with borders */
.smartphone {
  position: relative;
  /* width: 360px;
  height: 640px; */
  margin: auto;
  border: 16px transparent solid;
  box-shadow: 0px 0px 0px 5px pink;
  filter: drop-shadow(8px 8px 10px gray);
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  background-image: url("https://reactivity.s3.us-west-1.amazonaws.com/chatbot/phoneTexture1.jpg");
  background-size: cover;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 0px 3px pink;
  background: white;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  box-shadow: 0px 0px 0px 2px black;
  width: 327px;

  /* height: 519px; */
  /* background: #d0d3da; */
  background-image: url("https://reactivity.s3.us-west-1.amazonaws.com/chatbot/chatBackground.jpg");
  background-size: cover;
}

.content {
  box-shadow: 0px 0px 0px 0px #d0d3da;
  /* background: #d0d3da; */
  background-image: url("https://reactivity.s3.us-west-1.amazonaws.com/chatbot/chatBackground.jpg");
  background-size: cover;
}

.content::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(228, 240, 227, 0.527);
}

.jKLQUD {
  background: transparent !important;
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px 0px !important;
  margin: 0 0px 0px 0px !important;
  padding: 0px !important;
}

.bxtiur {
  height: 97% !important;
}

.bOvVpV {
  height: 97% !important;
}

.css-1mz1l2x-MuiStepContent-root {
  padding-left: 0px !important;
}

.css-ib0je1 {
  padding-left: 0px !important;
}

.centered-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
